.ourWorks-wrapper .img {
  border-radius: 20px;
  position: relative;
  z-index: 1;
}
.ourWorks-wrapper .img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* border-radius: 20px; */
  z-index: 5;
  width: 100%;
  height: 100%;
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.ourWorks-wrapper > div:nth-child(1) .img::after {
  background-image: url("/public/assets/img/blurImages/RB_blur.png");
}
.ourWorks-wrapper > div:nth-child(2) .img::after {
  background-image: url("/public/assets/img/blurImages/AY_blur.png");
}
.ourWorks-wrapper > div:nth-child(3) .img::after {
  background-image: url("/public/assets/img/blurImages/Lp_blur.png");
}
.ourWorks-wrapper > div:nth-child(4) .img::after {
  background-image: url("/public/assets/img/blurImages/humus_blur.png");
}
.ourWorks-wrapper > div:nth-child(5) .img::after {
  background-image: url("/public/assets/img/blurImages/ML_blur.png");
}
.ourWorks-wrapper > div:nth-child(6) .img::after {
  background-image: url("/public/assets/img/blurImages/challo_blur.png");
}
.ourWorks-wrapper img {
  border-radius: 20px;
}
.ourWorks-wrapper .img:hover::after {
  display: block;
}

.ourWorks-wrapper > div:nth-child(2n) {
  display: flex;
  justify-content: flex-end;
}
