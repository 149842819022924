* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
  font-family: Oxygen;
}
.fadeEntry {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1.2s ease-in-out;
}

@media (max-width: 600px) {
  body {
    font-size: 80%;
  }
}
