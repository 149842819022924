.website {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 2.4rem !important;
  line-height: 152%;

  /* text-decoration-line: underline; */
  background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  opacity: 0.9;
}
.investment {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 4.768rem;
  line-height: 152%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.cities {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 4.768rem;
  line-height: 152%;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.latestAchievement .title {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 1.563rem;
  line-height: 152%;
  color: #fafafa;
}
.latestAchievement .achievement-text {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 152%;
  color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.individualWorks .swiper-pagination-bullet {
  background-color: rgb(77, 77, 77);
}
.individualWorks .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
}
.MoreFromUs .title {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 1.563rem;
  line-height: 152%;
  color: #fafafa;
}

.aboutComp_h2{
  font-weight: 600;
}


.overview h3{
  font-size: 24px;
  opacity: .5;
  font-weight: 500;
}

@media only screen and (max-width: 800px) {

  .website {
    font-size: 20px !important;
  }

  .comp_name{
    margin-bottom: 1.5rem !important;
  }

  .overview h3{
    font-size: 18px;
    font-weight: 600;
  }

  .product-links img{
    width: 20px;
  }
  
  .latestAchivement{
    display: flex;
    flex-direction: column !important;
  }
  .company-investment{
    /* justify-content: flex-start !important; */
    align-items: flex-start !important;
  }
  .company-cities{
    align-items: flex-start !important;
  }
  .investment {
    font-size: 3rem;
  }
  .cities {
    font-size: 3rem;
  }
}

