.QualityPolicyPriciples-container .section-title{
    margin-top: 150px;
}

.principle-list-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    position: relative;
}

.principle-id-container{
    position: relative;
}

.principle-id-container::before{
    content: "";
    position: absolute;
    top: 0px;
    width: 0px;
    /* background-color: white; */
    border-left: 2px dotted cyan;
    height: 100%;
    left: 50%;
}

.priciple-list:last-child .principle-id-container::before {
    display: none;
}

.priciple-list{
    display: flex;
    justify-content: space-between;
    gap: 50px;
    /* margin-bottom: 120px; */
}

.priciple-list h2{
    font-size: 32px;
    font-weight: 700;
    text-align: right;
    width: 30%;
}

.principle-id{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.principle-id p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 100;
    margin-bottom: 0px;
    max-width: fit-content;
    font-size: 18px;
    font-weight: 700;
}

.principle-id::after{
    content: '';
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    z-index: 99;
}

.priciple-list .priciple-description{
    width: 60%;
    padding-bottom: 120px;
}

.principle-id-container{
    width: 10%;
    display: flex;
    justify-content: center;
}

.priciple-para{
    margin-top: 80px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.priciple-para p{
    width: 80%;

}

.foundername-container{
    margin: 120px;
}


.foundername-container h1{
    font-size: 42px;
    font-weight: 700;
    text-align: right;
    background: linear-gradient(180deg, #2AF598 0%, #08AEEA 100%);
    -webkit-background-clip: text; /* Clips background to text */
    -webkit-text-fill-color: transparent; /* Makes the text itself transparent */
}

.foundername-container p{
    text-align: right;
    margin-top: 10px;
}

.resp-principle-list-container{
    display: none;
}

.QualityPolicyPriciples-container .section-title p{
    text-align: center;
}

@media screen and (max-width: 995px){

    .priciple-list h2{
        font-size: 26px
    }
}

@media screen and (max-width: 768px){

   .principle-list-container{
    display: none;
   }

   .priciple-list{
    gap: 20px;
   }

   .resp-principle-list-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    position: relative;
   }

   .resp-priciple-data{
    display: flex;
    flex-direction: column;
   }

   .resp-priciple-data h2{
    width: 100%;
    text-align: left;
   }

   .resp-priciple-data p{
    width: 100% !important;
    text-align: left;
   }

   .principle-id-container{
    width: fit-content;
   }

   .priciple-list .priciple-description{
    padding-bottom: 60px;
   }

   .QualityPolicyPriciples-container .section-title{
    margin-top: 50px;
   }

   .resp-principle-list-container{
    margin-top: 40px;
   }

   .priciple-para{
    margin-top: 50px;
   }

   .foundername-container{
    margin: 0px;
    margin-top: 40px;
   }
}

@media screen and (max-width: 500px){

    .principle-id{
        width: 40px;
        height: 40px;
    }

    .principle-id::after{
        width: 35px;
        height: 35px;
    }

    .priciple-list h2{
        font-size: 20px;
    }

    .foundername-container h1{
        font-size: 32px;
    }
}