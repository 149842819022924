section.offerings{
    position: relative;
    /* padding: 2.5rem 2.5rem 6rem 2.5rem !important; */
    padding-bottom: 6rem !important;
}

.offering-gradient{
    position: absolute;
    top: 20%;
    left: -300px;
    opacity: .6;
}

.offering-gradient img{
    width: 1200px;
}


.offerings_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offerings_container h1{
    font-weight: 700;
    font-size: calc(1.375rem + 1.5vw);
    line-height: 152%;
}

.offerings_container .service-para{
    text-align: center;
    width: 800px;
}


.offerings-card-container{
    width: 100%;
    margin-top: 80px;
}

.offerings-card-container .swiper-slide{
    width: 420px;
    height: auto !important;
    padding: 40px 24px 24px 24px;
    position: relative;
    border-radius: 13px;
}


.offering-card-background{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: #D9D9D9;
    border-radius: 13px;
}

.offering-card h3{
    margin-top: 20px;
    font-size: 24px;
    font-weight: 700;
}

.offering-card  p{
    color: #E4E4E4 !important;
    font-size: 16px;
}

.offerings-card-container .arrow-container{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 14px;
    width: max-content;
}

.offerings-card-container .arrow-container img{
    position: relative;
    width: auto;
    cursor: pointer;
}



.offerings-card-container .offers-next-icon{
    transform: rotate(180deg);
    cursor: pointer;
} 


.offering-icon{
    width: 66px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #26EE9F;
    border-radius: 50%;
}


@media screen and (max-width: 600px){

    .offerings_container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .offerings_container .service-para{
        width: 100%;
        text-align: left;
        margin-top: 1rem;
    }

    .offerings-card-container .swiper-slide{
        width: 300px;
        padding: 32px 16px 16px 16px;
    }

    .offerings-card-container{
        margin-top: 40px;
    }
    

    .offering-card h3{
        font-size: 18px;
    }

    .offering-card p{
        font-size: 14px;
    }

    .offerings-card-container .prev-icon{
        left: 31%;
    }

    .offerings-card-container .next-icon{
        right: 31%;
    }

}

