.collaborations .swiper-slide {
    /* width: fit-content !important; */
    /* height: 300px !important; */
    display:flex;
    /* background: #eee; */
    align-items: center;
    flex-direction: column;
    justify-content:center;
    transition:all 200ms linear;
    transform: scale(0.8);
    filter: grayscale(100%);
    color: gray;
  }
  
  .collaborations .swiper-slide p{
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
  
  
  .collaborations .swiper-slide img{
    object-fit: contain;
    width: 150px;
    height: 100px;
    cursor: pointer;
  }
  
 .collaborations .swiper-slide-active{
    /* transform: scale(1); */
    /* background: red; */
    filter: grayscale(0%);
    
  }
  
  .collaborations .swiper-slide-active p{
    color: white;
    margin-top: 32px;

  }
  .collaborations .swiper-slide__content {
  /* height: 300px; */
  }


  .clients-swiper-container{
    position: relative;
  }

  .clients-swiper-container .left-arrow{
    position: absolute;
    top: 40%;
    right: 100%;
    cursor: pointer;
    /* transform: translateY(50%); */
  }

  .clients-swiper-container .left-arrow img{
    width: 15px;
  }


  .clients-swiper-container .right-arrow{
    position: absolute;
    top: 40%;
    left: 100%;
    cursor: pointer;
    /* transform: translateY(50%); */
  }

  .clients-swiper-container .right-arrow img{
    width: 15px;
  }


.seemore_img{
  cursor: pointer;
  /* margin-left: 8px; */
}

.readmore-arrow{
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.readmore-arrow span{
  margin-bottom: 0px;
  font-size: .8rem;
}

.readmore-arrow:hover{
  color: #1edcb6;
}

.readmore-arrow img{
  transform: rotate(180deg);
  width: 3rem;
  
}

@media screen and (max-width:600px){

  .collaborations .swiper{
    width: 90%;
  }

  .collaborations .about p{
    margin-top: 1.5rem;
  }

  .collaborations .swiper-slide img{
    object-fit: contain;
    width: 90px;
    height: 80px;
   }

   .clients-swiper-container .left-arrow{
    position: absolute;
    top: 40%;
    right: 95%;
    cursor: pointer;
    /* transform: translateY(50%); */
  }

  .clients-swiper-container .right-arrow{
    position: absolute;
    top: 40%;
    left: 95%;
    cursor: pointer;
    /* transform: translateY(50%); */
  }

  .clients-swiper-container .left-arrow img{
    width: 10px;
  }

  .clients-swiper-container .right-arrow img{
    width: 10px;
  }

}