.quality-policy-content h1, .missionvision-card h1{
    font-weight: 700;
}

.quality-policy-content  p{
    margin-top: 12px;
    width: 500px;
}

/* .quality-policy-gradient{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 300px;
    overflow: hidden;
} */


.quality-policy-gradient{
    position: absolute;
    top: -200px;
    right: -450px;
    opacity: .9;
    overflow: hidden;
    width: 1500px;
    height: 1000px;
}

.quality-policy-gradient img{
    /* width: 1500px;
    height: 1000px; */
    width: 100%;
    height: 100%;
}


.policy-priciple-gradient{
    position: absolute;
    top: 200px;
    left: -530px;
    opacity: .9;
    overflow: hidden;
    width: 1100px;
    height: 1000px;
}


.policy-priciple-gradient img{
    /* width: 1100px;
    height: 1000px; */
    width: 100%;
    height: 100%;
}


.policy-priciple-gradient2{
    position: absolute;
    top: 200%;
    right: -100px;
    opacity: .9;
    overflow: hidden;
    width: 900px;
    height: 900px;
    transform: rotate(180deg);
}


.policy-priciple-gradient2 img{
    /* width: 900px;
    height: 900px;
    transform: rotate(180deg); */
    width: 100%;
    height: 100%;
}

.blur-img{
    backdrop-filter: blur(4px)
}


.quality-policy-container-wrapper{
    background-image: url(/public/assets/img/service-gradient.png), url(/public/assets/img/service-gradient.png), url(/public/assets/img/service-gradient.png);
	background-position: 170% -100px, -300px center, 130% 75%;
	background-size: 900px;
	background-repeat: no-repeat;
    position: relative;
}

.quality-policy-img{
    position: absolute;
    top: 140px;
    right: 100px;
    
}


.quality-policy-img img{
    width: 500px;
    height: 500px;
}

.quality-policy-content span, .missionvision-card span{
    color: rgba(41, 242, 156, 1);
    font-size: 18px;
    font-weight: 600;
}

.missionvision-container{
    display: flex;
    align-items: flex-start;
    gap: 50px;
    margin-top: 180px;
}

.missionvision-card{
    flex: 1;
}

.missionvision-card:first-child{
    text-align: right;
}

.h1-container{
    position: relative;
}

.h1-container div{
    width: 70px;
    height: 3px;
    background-color: rgba(41, 242, 156, 1);
    position: absolute;
    top: 100%;
}

.h1-container h1{
    padding-bottom: 10px;
}

.missionvision-card:nth-child(odd) .h1-container div{
    right: 0px !important;
}

.missionvision-card p{
    margin-top: 12px;
}

.missionvision-card:last-child{
    text-align: left;
}

@media screen and (max-width: 530px){

    .quality-policy-content  p{
        width: 100%;
    }

    .quality-policy-container{
        background-image: url(/public/assets/img/service-gradient.png), url(/public/assets/img/service-gradient.png), url(/public/assets/img/service-gradient.png);
        background-position: -10% -100px, -300px center, bottom right;
        background-size: 600px;
        background-repeat: no-repeat;
    }

    
    .policy-priciple-gradient2{
        width: 300px;
        height: 400px;
        /* top: 0px; */
    }

    .quality-policy-gradient{
        width: 700px;
        height: 700px;
        top: -200px;
        right: -200px;
    }

    .policy-priciple-gradient{
        width: 300px;
        height: 400px;
    }

    .quality-policy-img img{
        display: none;
    }
    
    .missionvision-container{
        flex-direction: column;
        gap: 30px;
    }

    .missionvision-card:first-child{
        text-align: left;
    }

    .missionvision-card:nth-child(odd) .h1-container div{
        left: 0px !important;
    }

    .missionvision-container{
        margin-top: 50px;
    }
    
}