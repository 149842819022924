.overlayImage::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	/* background: linear-gradient(45deg, #060606d6, #2e2e2eb6); */
	background: linear-gradient(45deg, #060606d6, #2e2e2e2c);
	width: 100%;
	height: 100%;
  border-radius: 10px;
}

.blogs h1 {
	font-family: Oxygen;
	font-style: normal;
	font-weight: bold;

	line-height: 152%;
	color: #fafafa;
}
.tag {
	font-family: Oxygen;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 152%;
	background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.date {
	font-family: Oxygen;
	font-style: normal;
	font-weight: normal;
	font-size: 0.85rem;
	line-height: 152%;
	/* or 30px */

	color: #7d7d7d;
}
.blogs-pagination {
	font-family: Oxygen;
	font-style: normal;
	font-weight: bold;
	font-size: 1.4rem;
	line-height: 152%;
	width: 4.5rem;
	text-align: end;
}
/*
.media-scroller::-webkit-scrollbar {
  display: none;
}
.card-element {
  display: grid;
  grid-template-rows: min-content;
  gap: 1rem;
}
.card-element img {
  inline-size: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center bottom;
  border-radius: 10px;
}
.card-element small {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 152%;
  color: #7d7d7d;
  border: 1px solid #000000;
}
.card-element h3 {
  min-height: 4.063rem;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 1.9rem;
  line-height: 152%;
  color: #fafafa;
  border: 1px solid #000000;
}
.card-element p {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 152%;
  color: #fafafa;
  border: 1px solid #000000;
}
.allBlogs a {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 152%;

  background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-element a {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 152%;

  border: 1px solid #000000;
  background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.media-scroller {
  display: grid;
  gap: 2rem;
  grid-auto-flow: column;
  grid-auto-columns: 42%;
  overflow-x: auto;
  overscroll-behavior-inline: contain;

  -ms-overflow-style: none; 
  scrollbar-width: none; 
  cursor: grab;
}
.snap-inline {
  scroll-snap-type: inline mandatory;
}
.snap-inline > * {
  scroll-snap-align: start;
}

@media (max-width: 600px) {
  .media-scroller {
    grid-auto-columns: 100%;
  }
} */
