.ourAsscociationContainer{
    width: 100%;
    
    background-color: #0A0A0A;
    padding: 76px 0px;
}
.OurAssociations{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.OurAssociations h3{
    font-weight: 700;
    font-size: calc(1.375rem + 1.5vw);
    line-height: 152%;
}

.AssociationContainer{
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AssociationContainer img{
    width: 25% !important;
    height: auto;
    object-fit: contain;
}

.AssociationContainer2{
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.AssociationContainer2 img{
    margin: 0px 60px;
}
.AssociationContainer2 .fusebo{
    width: 18% !important;
    height: auto;
    object-fit: contain;
}

.AssociationContainer2 .niod{
    width: 30% !important;
    height: auto;
    object-fit: contain;
}

@media only screen and (max-width: 700px) {
    .AssociationContainer{
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .AssociationContainer2{
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .AssociationContainer2 img{
        margin: 0px 30px;
    }

    .AssociationContainer2 .fusebo{
        width: 22% !important;
        height: auto;
        object-fit: contain;
    }
    
    .AssociationContainer2 .niod{
        width: 40% !important;
        height: auto;
        object-fit: contain;
    }
}

