.ourTeam h1 {
	font-family: Oxygen;
	font-style: normal;
	font-weight: bold;
	/* font-size: 61.04px; */
	line-height: 152%;
	/* identical to box height, or 93px */

	/* #FAFAFA */

	color: #fafafa;
}
.boxes {
	font-size: 16px;
}
.row-1 > div {
	--size: 9.5rem;
	width: var(--size);
	height: var(--size);
	border-radius: 10px;
	position: relative;
	background: black;
	transition: all 0.6s ease-out;
	/* color: #1fddb3; */

	/* outline: 2px solid #5a5a5a; */
}
.row-1 > div:hover {
	color: #1fddb3 !important;
}
.row-1 > div:hover::after {
	/* outline: 2px solid transparent; */
	color: #1fddb3 !important;
	background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
}
.row-1 > div::after {
	content: "";
	position: absolute;
	border-radius: 10px;
	width: calc(var(--size) + 2px);
	height: calc(var(--size) + 2px);
	top: -1;
	left: -1;
	transition: all 0.6s ease-out;
	background: linear-gradient(180deg, #6e6e6e 0%, #808080 100%);
	z-index: -22;
}

.gradient-active {
	/* outline: 2px solid transparent !important; */
	color: #1fddb3;
}
.gradient-active::after {
	background: linear-gradient(180deg, #2af598 0%, #08aeea 100%) !important;
}

.team-boxcard span{
	color: #A0A0A0;
}

@media (max-width: 600px) {
	.boxes {
		font-size: 12px;
	}
	.row-1 > div {
		--size: 6rem;
	}
}
