.OurWorksv2{
    margin-bottom: 103px;
}

.sectorTagsContainer{
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}
.sectorTags{
    padding: 6px 13px;
    background: linear-gradient(117.46deg, rgba(255, 255, 255, 0.61) -63.15%, rgba(255, 255, 255, 0) 100.72%);
    backdrop-filter: blur(27.5px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 39.2353px;
}
.sectorTags span{
    margin-left: 10px;
}

.projectList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 20px;
    justify-content: space-between;
    margin-top: 80px;
   
}
.projectList .project-card{
    width: calc(32% - 20px);
    cursor: pointer;
   
}

.projectList .project-card img{
    width: 100%;
    height: auto;
}



.gradientDesign{
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;
}

.viewMoreBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    
}
.viewMoreBtn span{
    font-weight: 700;
    font-size: 31.25px;
    background: linear-gradient(180deg, #2AF598 0%, #08AEEA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-right: 13px;
    border: 1px solid #000000;
    cursor: pointer;
}
.viewMoreBtn img{
    cursor: pointer;
}
.up-arrow{
    transform: rotate(180deg);
}

@media only screen and (max-width: 800px) {
    .projectList{
        row-gap: 40px;
    }
    /* .projectList > div:nth-child(2n) {
        display: flex;
        justify-content: flex-end;
    } */
    .projectList .project-card .project-card-child{
        width: 100%;
    }
    .projectList .project-card{
        width: 100% !important;
        cursor: pointer;
    }
    .projectList .project-card img{
        /* width: 75% !important;
        cursor: pointer; */
    }
    .viewMoreBtn{
        margin-top: 25px;
        
    }
    .viewMoreBtn span{
      font-size: 20px;
      margin-right: 8px;
    }
    .viewMoreBtn img{
        width: auto;
        height: 8px;
    }
    
}