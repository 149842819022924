.individualWorksImages .swiper {
	width: 100%;
	height: 400px !important;
}

.individualWorksImages .swiper-slide {
	width: max-content !important;
	height: 100%;
}

.individualWorksImages .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@media (max-width: 600px) {
	.individualWorksImages .swiper-slide {
		width: 100% !important;
		height: 100%;
	}
}
