.services-slider .services_container{
    min-width: 600px;
    height: 550px;
    position: relative;
    /* padding-right: 70px; */
}


.slide_container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    /* justify-content: center; */
}


.services_container .swiper_arrows img.up-arrow{
    position: absolute;
    top: 0px;
    right: -70px;
    transform: rotate(360deg);
    cursor: pointer;
}

.services_container .swiper_arrows img.down-arrow{
    position: absolute;
    bottom: 0px;
    right: -70px;
    cursor: pointer;
    /* transform: rotate(360deg); */
}

.slide_container h1{
    font-size: 24px;
    color: #666666;
}

.slide_container p{
    font-size: 16px;
    /* display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
	text-overflow: ellipsis; */
	color: #666666;
    width: 380px;
}


.services_container .swiper-slide-active h1{
    font-weight: 600;
    /* scale: 1.3; */
    font-size: 26px;
    color: white;
}

.services_container .swiper-slide-active p{
    color: white;
    display: none;
}

.swiper-slide-active .slide_container{
    height: 100%;
    justify-content: center;
}




@media screen and (max-width: 600px){

    .services-slider .services_container{
        max-width: 85%;
        min-width: auto;
        margin-top: 50px;
    }

    .slide_container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

    .slide_container h1{
        font-size: 16px;
    }

    
    .services_container .swiper-slide-active h1{
        font-size: 18px !important;
    }

    .slide_container p{
        font-size: 12px;
        width: 100%;
    }

    .services_container .swiper_arrows img.up-arrow{
        position: absolute;
        top: 0px;
        left: -40px;
        transform: rotate(360deg);
        cursor: pointer;
    }
    
    .services_container .swiper_arrows img.down-arrow{
        position: absolute;
        bottom: 0px;
        left: -40px;
        cursor: pointer;
        /* transform: rotate(360deg); */
    }
}


/* .Servicesv2{
    position: relative;
}

.services-gradient{
    position: absolute;
    bottom: 0px;
    left: 0px;
} */

.Servicesv2_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.Servicesv2_container h1{
    font-weight: 700;
    font-size: calc(1.375rem + 1.5vw);
    line-height: 152%;
}

.Servicesv2_container .service-para{
    text-align: center;
    width: 800px;
}


.servicesv2_card_container{
    width: 100%;
    display: flex;
    column-gap: 40px;
    row-gap: 10px;
    flex-wrap: wrap;
    margin-top: 80px;
}

.servicesv2_card{
    width: calc(33% - 24px);
    padding: 24px 0px;
   
    border-radius: 13px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

/* .servicesv2_card:nth-child(1) {
 
     padding: 24px 32px;
     background: rgba(149, 149, 149, 0.2);;
  } */

  /* .servicesv2_card:nth-child(1) .service_card_background{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #959595;
} */

.servicesv2_card h3{
    margin-top: 20px;
    font-size: 23px;
    font-weight: 700;
}

.servicesv2_card p{
    color: #E4E4E4 !important;
    font-size: 16px;
} 

.service-icon{
    width: 66px;
    height: 66px;
    background-color: #26EE9F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 1000px){
    .servicesv2_card{
        width: calc(50% - 24px);
    }
}
