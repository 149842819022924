.service-team-container{
    position: relative;
}

.service-gradient{
    position: absolute;
    top: 30%;
    left: -490px;
    opacity: .5;
}

.service-gradient img{
    width: 1500px;
    height: 1000px;
}



/* .offerings-associations-container{
    position: relative;
}


.offerings-gradient{
    position: absolute;
    top: 1%;
    left: -200px;
}


.offerings-gradient */