.swiper {
  width: 100%;
  height: auto;
  /* z-index: 500; */
}

.ourSuccess-title {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  /* font-size: 2.5rem; */
  line-height: 152%;
  color: #fafafa;
}
.ourSuccess-qoute,
.ourSuccess-name {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
}
.ourSuccess-name {
  font-size: 1.5rem;
}
.ourSuccess-qoute {
  font-size: 1.1rem;
}
.ourSuccess-company {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 152%;
  background: linear-gradient(180deg, #2af598 0%, #08aeea 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ourSuccess-pagination {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 152%;
  width: 4.5rem;
  text-align: end;
}

@media (max-width: 600px) {
  /* .ourSuccess-name {
    font-size: 1.25rem;
  }
  .ourSuccess-qoute {
    font-size: 1rem;
  }
  .ourSuccess-company {
    font-size: 1rem;
  } */
}
