.navLinks a {
	font-family: Oxygen;
	font-style: normal;
	font-weight: bold;
	font-size: 1.2rem;
	line-height: 152%;
}
@media (max-width: 600px) {
	/* body {
    font-size: 70%;
  } */
	.navLinks a {
		font-size: 1rem;
	}
	/* .logo > img {
		width: 70%;
	} */
}
